@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@200;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: white;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  background-color: #f7f7fa;
  border: 1px dotted #dddee3;
}
.cdk-drag-placeholder > * {
  visibility: hidden;
}

.cdk-drag-preview .cdk-drag {
  transform: translateX(4rem);
}

:root {
  --theme-color-skills-job: #f75c03;
}

:root {
  --theme-font-title: Epilogue;
  --theme-font-text: Inter;
}

body {
  font-family: var(--theme-font-text), sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: var(--theme-font-title), serif;
}

h5,
h6 {
  font-family: var(--theme-font-text), sans-serif;
}